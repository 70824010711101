import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import RampFeaturesSection from '../../components/RampFeaturesSection/RampFeaturesSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';


const FeaturesPage = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-site-header wpo-header-style-3'} />
            <PageTitle pageTitle={'Features'} pagesub={'Service'} />
            <RampFeaturesSection hclass={"wpo-service-area section-padding"} ServiceBtn={true} />
            <Footer hclass={'wpo-site-footer'} />
            <Scrollbar />
        </Fragment>
    )
};
export default FeaturesPage;
