import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from '../HomePage/HomePage';
import AboutPage from '../AboutPage/AboutPage';
import PartsPage from '../PartsPage/Parts';
import RampsPage from '../RampsPage/RampsPage'; // Assuming RampsPage is for /ramps
import FeaturesPages from '../FeaturesPage/FeaturesPage';
import ContactPage from '../ContactPage/ContactPage';
import ErrorPage from '../ErrorPage/ErrorPage';

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="parts" element={<PartsPage />} />
          <Route path="features" element={<FeaturesPages />} />
          
          {/* Ramps routes */}
          <Route path="ramps" element={<RampsPage />} />
          <Route path="ramps/:type" element={<RampsPage />} /> {/* Dynamic route for ramp type */}
          <Route path='contact' element={<ContactPage />} />
          <Route path='404' element={<ErrorPage />} /> 
          <Route path='*' element={<ErrorPage />} /> 
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AllRoute;
