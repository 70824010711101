import React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import Services from "../../api/Services";



const ServiceSection = (props) => {

    const { SectionTitleShow = true, } = props

    return (
        <div className={"" + props.hclass}>
            <div className="container">
                {SectionTitleShow && (
                    <div className="row">
                        <SectionTitle subtitle={'Versatile Ramps'} title={'Our Ramps Serve Many'} titleColor={'Industries'} />
                    </div>
                )}
                <div className="row align-items-center">
                    {Services.slice(0, 6).map((service, item) => (
                        <div className="col-lg-4 col-md-6 col-12" key={item}>
                            <div className="wpo-service-item ">
                                <div className="d-flex">
                                    <div className="icon me-3 w-25">
                                        <i><img src={service.image} alt="" /></i>
                                    </div>
                                    <h2>{service.title}</h2>
                                </div>
                                <p>{service.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>


    );
}

export default ServiceSection;


