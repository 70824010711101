import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import RampsList from '../../components/RampsList/RampsList'
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';


const RampsPage = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-site-header wpo-header-style-3'} />
            <PageTitle pageTitle={'Ramps'} pagesub={''} />
            <RampsList />
            <Footer hclass={'wpo-site-footer'} />
            <Scrollbar />
        </Fragment>
    )
};
export default RampsPage;

