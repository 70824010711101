import React from 'react';
import { Autoplay, Navigation, A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';
import hero3 from '../../images/slider/moving-83.jpg';
import hero4 from '../../images/slider/delivery-7.jpg';
import hero5 from '../../images/slider/equipment-59.jpg';

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Hero3 = (props) => {

    return (
        <section className={"" + props.hclass}>
            <Swiper
                modules={[Autoplay, Navigation, A11y, Pagination]}
                spaceBetween={0}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                  }}
                slidesPerView={1}
                loop={true}
                speed={1800} // Keep the speed for now, but can reduce for testing
                parallax={true}
                navigation
                pagination={{ clickable: true }} // Ensure pagination is clickable
            >
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero3})`, minHeight: '100vh' }}>
                        <div className="container-fluid">
                            <div className="slide-content">
                                <div className="slide-title">
                                    <h2>Move <span>Smarter</span> with Durable Ramps</h2>
                                </div>
                                <div className="slide-text">
                                    <p>Our durable, lightweight ramps are designed for effortless loading and unloading, making residential and commercial moves faster and safer.</p>
                                </div>
                                <div className="clearfix"></div>
                                <div className="slide-btns">
                                    <ul>
                                        <li><Link onClick={ClickHandler} className="theme-btn-s2" to="/ramp-features">Ramp Features</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero4})`, minHeight: '100vh' }}>
                        <div className="container-fluid">
                            <div className="slide-content">
                                <div className="slide-title">
                                    <h2>Efficiency <span>Starts</span> with the Right Ramp</h2>
                                </div>
                                <div className="slide-text">
                                    <p>Melcher ramps ensure smooth, secure handling of goods with high-traction surfaces, helping delivery teams stay on schedule with ease.</p>
                                </div>
                                <div className="clearfix"></div>
                                <div className="slide-btns">
                                    <ul>
                                        <li><Link onClick={ClickHandler} className="theme-btn-s2" to="/ramp-features">Ramp Features</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero5})`, minHeight: '100vh' }}>
                        <div className="container-fluid">
                            <div className="slide-content">
                                <div className="slide-title">
                                    <h2><span>Tough</span> Ramps for Heavy Lifting</h2>
                                </div>
                                <div className="slide-text">
                                    <p>Built tough for any job, our ramps provide the strength and stability needed to safely transport heavy equipment across diverse industries.</p>
                                </div>
                                <div className="clearfix"></div>
                                <div className="slide-btns">
                                    <ul>
                                        <li><Link onClick={ClickHandler} className="theme-btn-s2" to="/ramp-features">Ramp Features</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                ...
            </Swiper>
        </section>
    );
}

export default Hero3;
