import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Hero3 from '../../components/hero3/Hero3';
import AboutS3 from '../../components/AboutSectionS3/AboutSectionS3';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import SkillSection from '../../components/SkillSection/SkillSection';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import SImg from '../../images/skill.jpg'
const HomePage = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-site-header wpo-header-style-3'} />
            <Hero3 hclass={'wpo-hero-slider wpo-hero-slider-s2'}/>
            <AboutS3 />
            <ServiceSection hclass={"wpo-service-area"} ServiceBtn={true} />
            <ProjectSection hclass={'wpo-project-area'} SectionTitleShow={true} />
            <SkillSection hclass={'wpo-skill-section'} Akimg={SImg}/>
            <Footer hclass={'wpo-site-footer'} />
            <Scrollbar /> 
        </Fragment>
    )
};
export default HomePage;