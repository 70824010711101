import pimg1 from '../images/heavy-duty-57.jpg';
import pimg2 from '../images/medium-duty-62.jpg';
import pimg3 from '../images/light-duty-49.jpg';
import pimg4 from '../images/ez-slide-32.jpg';


const Projects = [
    {
        id: '01',
        subtitle: 'Ramps for moving your heaviest items',
        title: 'Heavy-Duty 2 in 1',
        slug: 'heavy-duty-ramps',
        pimg: pimg1,
    },
    {
        id: '02',
        subtitle: 'Ramps for loads up to 3,000 pounds',
        title: 'Medium Duty',
        slug: 'medium-duty-ramps',
        pimg: pimg2,
    },

    {
        id: '03',
        subtitle: 'Ramps for short-haul loads up to 1,500 pounds',
        title: 'Light Duty',
        slug: 'light-duty-ramps',
        pimg: pimg3,
    },

    {
        id: '04',
        subtitle: 'Ramps for light-duty maneuverability',
        title: 'Easy Slide',
        slug: 'Easy Slide Ramps',
        pimg: pimg4,
    }



]
export default Projects;






