import React, { useState, useEffect, useCallback } from 'react';
import Turnstile from 'react-turnstile';

const ContactForm = () => {
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        note: ''
    });

    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [turnstileToken, setTurnstileToken] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const validate = (values) => {
        let errors = {};
        if (!values.name) {
            errors.name = 'Name is required';
        }
        if (!values.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
            errors.email = 'Email address is invalid';
        }
        if (!values.phone) {
            errors.phone = 'Phone is required';
        }
        if (!values.subject) {
            errors.subject = 'Subject is required';
        }
        if (!values.note) {
            errors.note = 'Message is required';
        }
        if (!turnstileToken) {
            errors.turnstile = 'Please complete the CAPTCHA';
        }
        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const handleFormSubmission = useCallback(async () => {
        setIsLoading(true);
        setIsError(false);
        setIsSuccess(false);

        const formData = new FormData();
        formData.append('name', formValues.name);
        formData.append('email', formValues.email);
        formData.append('phone', formValues.phone);
        formData.append('subject', formValues.subject);
        formData.append('note', formValues.note);
        formData.append('cf-turnstile-response', turnstileToken);

        try {
            const response = await fetch('https://formfling.com/s/KLm807Hz7BXhB8S08uuF-BpC9ftN9Phw0GGM773Zo-779mx8', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setIsSuccess(true);
                setFormValues({
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    note: ''
                });
                setTurnstileToken('');
            } else {
                setIsError(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, [formValues, turnstileToken]);

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            handleFormSubmission();
            setIsSubmitting(false);
        }
    }, [formErrors, isSubmitting, handleFormSubmission]);

    return (
        <form method="post" className="contact-validation-active" onSubmit={handleSubmit}>
            <div>
                <input
                    type="text"
                    className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                    name="name"
                    id="name"
                    placeholder="Your Name*"
                    value={formValues.name}
                    onChange={handleChange}
                />
                {formErrors.name && <span className="invalid-feedback">{formErrors.name}</span>}
            </div>
            <div>
                <input
                    type="email"
                    className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                    name="email"
                    id="email"
                    placeholder="Your Email*"
                    value={formValues.email}
                    onChange={handleChange}
                />
                {formErrors.email && <span className="invalid-feedback">{formErrors.email}</span>}
            </div>
            <div>
                <input
                    type="text"
                    className={`form-control ${formErrors.phone ? 'is-invalid' : ''}`}
                    name="phone"
                    id="phone"
                    placeholder="Your Phone*"
                    value={formValues.phone}
                    onChange={handleChange}
                />
                {formErrors.phone && <span className="invalid-feedback">{formErrors.phone}</span>}
            </div>
            <div>
                <input
                    type="text"
                    className={`form-control ${formErrors.subject ? 'is-invalid' : ''}`}
                    name="subject"
                    id="subject"
                    placeholder="Subject*"
                    value={formValues.subject}
                    onChange={handleChange}
                />
                {formErrors.subject && <span className="invalid-feedback">{formErrors.subject}</span>}
            </div>
            <div className="fullwidth">
                <textarea
                    className={`form-control ${formErrors.note ? 'is-invalid' : ''}`}
                    name="note"
                    id="note"
                    placeholder="Message..."
                    value={formValues.note}
                    onChange={handleChange}
                ></textarea>
                {formErrors.note && <span className="invalid-feedback">{formErrors.note}</span>}
            </div>
            <div>
                <Turnstile
                    sitekey="0x4AAAAAAAkuN8yTza7gftGX"
                    onVerify={(token) => setTurnstileToken(token)}
                    onExpire={() => setTurnstileToken('')}
                />
                {formErrors.turnstile && <span className="invalid-feedback">{formErrors.turnstile}</span>}
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn" disabled={isLoading}>
                    {isLoading ? 'Sending...' : 'Get in Touch'}
                </button>
            </div>
            <div className="clearfix error-handling-messages">
                {isSuccess && (
                    <div id="success" style={{ display: 'block' }}>
                        Thank you, we will contact you as soon as possible.
                    </div>
                )}
                {isError && (
                    <div id="error" style={{ display: 'block' }}>
                        An error occurred. Please try again later.
                    </div>
                )}
            </div>
        </form>
    );
};

export default ContactForm;