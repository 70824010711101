import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/Melcher_Logo.png';

const ClickHandler = () => {
    window.scrollTo(10, 0);
};

export const getCurrentYear = () => {
    return new Date().getFullYear();
};

const Footer = (props) => {

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.charset = 'UTF-8';
        script.innerHTML = `
            (function(d, t, g, k) {
                var ph = d.createElement(t),
                s = d.getElementsByTagName(t)[0],
                t = (new URLSearchParams(window.location.search)).get(k);
                t && localStorage.setItem(k, t);
                t = localStorage.getItem(k);
                ph.type = 'text/javascript';
                ph.async = true;
                ph.defer = true;
                ph.charset = 'UTF-8';
                ph.src = g + '&v=' + (new Date()).getTime();
                ph.src += t ? '&' + k + '=' + t : '';
                s.parentNode.insertBefore(ph, s);
            })(document, 'script', '//huddle.edgemarketingdesign.com/?p=578&ph_apikey=a9f4c2d3749d0fc16f99d0afa2da15b6', 'ph_access_token');
        `;
        document.body.appendChild(script);
    }, []);

    return (
        <footer className={props.hclass}>
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <Link className="logo" to="/">
                                        <img src={logo} alt="Melcher Logo" />
                                    </Link>
                                </div>
                                <p>
                                    Discover durable, lightweight ramps from Melcher Manufacturing, perfect for moving,
                                    delivering, and transporting equipment across various industries.
                                </p>
                                <ul>
                                    <li>
                                        <Link onClick={ClickHandler} to="#">
                                            <i className="ti-facebook"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="#">
                                            <i className="ti-instagram"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Composite Ramps</h3>
                                </div>
                                <ul>
                                    <li><a href="/ramps/heavy">Heavy Duty 2-in-1</a></li>
                                    <li><a href="/ramps/medium">Medium Duty Single</a></li>
                                    <li><a href="/ramps/light">Light Duty Single</a></li>
                                    <li><a href="/ramps/easy">Easy-Slide</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>Contact Us</h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li>
                                            <i className="fi flaticon-location"></i>6017 E. Mission Ave <br /> Spokane Valley, WA 99212
                                        </li>
                                        <li>
                                            <i className="fi flaticon-telephone"></i>
                                            <a href="tel:+15095357626">1-509-535-7626</a> <br />
                                            <a href="tel:+18005414227">1-800-541-4227</a>
                                        </li>
                                        <li>
                                            <a href="mailto:sales@melcher-ramps.com">
                                                <i className="fi flaticon-email"></i>sales@melcher-ramps.com
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <ul>
                                <li>
                                    &copy; {getCurrentYear()} Melcher Manufacturing, Inc.  All Rights Reserved.
                                </li>
                                <li>
                                    Website By{' '}
                                    <Link to="https://edgemarketingdesign.com">Edge Marketing</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
